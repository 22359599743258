
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'

export default class WarehouseWMSOverview {
    private parent: any;
    private entity = "";
    private toastr: any;
    private element: HTMLElement;
    private dt: any;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.element = document.querySelector("#warehouse_wms_configurator") as HTMLElement;
        this.bindListeners();
        this.init();
        console.log("wms", this)
    }

    async init() {
        let rawTableHtml = '<div class="text-center"><table class="wms_warehouse_configurator_table"><tbody>';
        rawTableHtml += '</tbody></table><div class="mt-1"><button class="btn btn-xs btn-success m-1" data-mode="row" data-action="add"><i class="ti ti-plus"></i></button><button class="btn btn-xs btn-danger m-1" data-mode="row" data-action="remove"><i class="ti ti-minus"></i></button></div></div>'
        rawTableHtml += '<div class="mt-1 d-flex flex-column"><button class="btn btn-xs btn-success m-1" data-mode="column" data-action="add"><i class="ti ti-plus"></i></button><button class="btn btn-xs btn-danger m-1" data-mode="column" data-action="remove"><i class="ti ti-minus"></i></button></div>'
        this.element.innerHTML = rawTableHtml;
        if (!this.parent.data.wmsConfig ||this.parent.data.wmsConfig.length === 0) {
            for(let i=0;i < 10;i++) {
                this.addColumn();
                if (i <= 5) {
                    this.addRow();
                }
            }
        } else {
            this.parent.data.wmsConfig.forEach((row: any, i: number) => {
                this.addRow();
                    row.forEach((cell: any, k: number) => {
                        if (i === 0) {
                            this.addColumn();
                        }
                        const td = document.querySelector(`.wms_warehouse_configurator_table tr:nth-child(${i+1}) td:nth-child(${k+1})`) as HTMLTableCellElement | null
                        if(td) {
                            if (cell.mode && cell.id) {
                                this.selectTd(td, cell.id, cell.mode, cell.name)
                            }
                        }
                    })
            })
        }
        this.bindDragEvents();
        const warehouseLocationRequest = await Utils.entity.getAll(`warehouse_locations`, `erp/warehouses/${this.parent.id}`)
        if(warehouseLocationRequest.status === 200) {
            const warehouseLocationData = warehouseLocationRequest.data
            const parent = document.querySelector("#warehouse_wms_warehouse_locations") as HTMLElement;
            warehouseLocationData.forEach((warehouseLocation: any) => {
                const button = document.createElement("button");
                button.classList.add("btn")
                button.classList.add("btn-primary")
                button.style.marginRight = "4px"
                button.style.marginTop = "4px"
                button.setAttribute("data-mode", "LOCATION")
                button.setAttribute("data-id", warehouseLocation.id)
                button.setAttribute("data-name", warehouseLocation.name)
                button.draggable = true
                button.innerHTML = warehouseLocation.name
                button.addEventListener("dragstart", (e: any) => {
                    e.dataTransfer.setData("mode", button.getAttribute("data-mode"));
                    e.dataTransfer.setData("name", button.getAttribute("data-name"));
                    e.dataTransfer.setData("id", button.getAttribute("data-id"));
                })
                parent.appendChild(button);
            })
        }
    }
    addRow() {
        const tbody = this.element.querySelector("tbody");
        if (tbody) {
            let html = '<tr>'
            let columnCount = 0
            const firstRow = tbody.querySelectorAll("tr:first-child td")
            if (firstRow && firstRow.length > 0) {
                columnCount = firstRow.length
            }
            for(let i=0;i < columnCount;i++) {
                html += '<td></td>'
            }
            html += '</tr>'
            tbody.innerHTML = tbody.innerHTML + html
        }
    }

    removeRow() {
        const tbody = this.element.querySelector("tbody");
        if (tbody) {
            let lastTr = tbody.querySelector("tr:last-child")
            if (lastTr) {
                lastTr.remove()
            }
        }
    }
    addColumn() {
        const tbody = this.element.querySelector("tbody");
        if (tbody) {
            const rows = tbody.querySelectorAll("tr") as NodeListOf<HTMLTableRowElement>;
            if (rows && rows.length > 0) {
                rows.forEach((row) => {
                    row.innerHTML = row.innerHTML + '<td></td>'
                })
            }
        }
    }

    removeColumn() {
        const tbody = this.element.querySelector("tbody");
        if (tbody) {
            let lastTds = tbody.querySelectorAll("tr td:last-child") as NodeListOf<HTMLTableCellElement>
            if (lastTds && lastTds.length > 1) {
                lastTds.forEach((td) => {
                    td.remove()
                })
            }
        }
    }

    getEntityData(elem: any) {
        return {}
    }

    removeFromAllCells(id: string) {
        (document.querySelectorAll(".wms_warehouse_configurator_table td") as NodeListOf<HTMLTableCellElement>).forEach((td) => {
            const tdId = td.getAttribute("data-id");
            if (id === tdId) {
                td.removeAttribute("data-id");
                td.style.background = '#FFFFFF'
            }
        });
    }

    bindDragEvents() {
        (document.querySelectorAll(".wms_warehouse_configurator_table td") as NodeListOf<HTMLTableCellElement>).forEach((td) => {
            td.draggable = true;
            td.addEventListener('click', (e: any) => {
                if (e.detail === 2) {
                    td.removeAttribute("data-id");
                    td.style.background = '#FFFFFF'
                }
            });
            td.addEventListener("dragstart", (e: any) => {
                e.dataTransfer.setData("mode", td.getAttribute("data-mode"));
                e.dataTransfer.setData("name", td.innerHTML);
                e.dataTransfer.setData("id", td.getAttribute("data-id"));
            })

            td.addEventListener('drop', (e: any) => {
                e.preventDefault()
                const mode = e.dataTransfer.getData("mode");
                const name = e.dataTransfer.getData("name");
                const id = e.dataTransfer.getData("id");
                if (mode && id) {
                    this.selectTd(td, id, mode, name)
                }
            })
            td.addEventListener('dragover', (e) => {
                e.preventDefault()
            })
        });

    }
    selectTd(td: HTMLTableCellElement, id: string, mode: string, name: string) {
        if (id !== "PATH") {
            this.removeFromAllCells(id)
        }
        td.setAttribute("data-id", id)
        td.setAttribute("data-name", name)
        td.setAttribute("data-mode", mode)

        td.innerHTML = ''
        if(mode === "START") {
            td.style.background = '#25B264'
        } else if(mode === "END") {
            td.style.background = '#EA5354'
        } else if(mode === "PATH") {
            td.style.background = '#A7AAAE'
        } else {
            td.innerHTML = name
            td.style.background = '#001BA0'
        }
    }

    async save() {
        const map: { mode: string | null; id: string | null; name: string | null; }[][] = [];
        (document.querySelectorAll(".wms_warehouse_configurator_table tr") as NodeListOf<HTMLTableRowElement>).forEach((tr) => {
            const trMap: { mode: string | null; id: string | null; name: string | null; }[] = [];
            (tr.querySelectorAll("td") as NodeListOf<HTMLTableCellElement>).forEach((td) => {
                trMap.push({
                    mode: td.getAttribute("data-mode"),
                    id: td.getAttribute("data-id"),
                    name: td.getAttribute("data-name"),
                })
            })
            map.push(trMap)
        })
        await Utils.entity.upsert({
            id: this.parent.id,
            wmsConfig: map
        }, this.parent.entity)
        this.toastr.success(`${Utils.translate('erp.warehouse.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)

    }

    bindListeners() {
        (document.querySelector("#warehouse_wms_save") as HTMLButtonElement).addEventListener("click", (e) => {
            this.save();
        })
        document.querySelectorAll(".btn[draggable='true']").forEach((btn) => {
            btn.addEventListener("dragstart", (e: any) => {
                e.dataTransfer.setData("mode", btn.getAttribute("data-mode"));
                e.dataTransfer.setData("name", btn.getAttribute("data-name"));
                e.dataTransfer.setData("id", btn.getAttribute("data-id"));
            })
        });

        document.addEventListener('click', (e) => {
            const target = e.target as undefined | HTMLElement
            if (target && target.closest('.btn-xs[data-mode]')) {
                const btn = target.closest('.btn-xs[data-mode]') as HTMLButtonElement
                const mode = btn.getAttribute("data-mode")
                const action = btn.getAttribute("data-action")
                if (mode === "row") {
                    if (action === "add") {
                        this.addRow()
                    } else {
                        this.removeRow()
                    }
                } else {
                    if (action === "add") {
                        this.addColumn()
                    } else {
                        this.removeColumn()
                    }
                }
                this.bindDragEvents();
            }
        })
    }
}